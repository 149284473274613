<template>
  <div class="modal" role="dialog">
    <div class="admins md-layout-item">
      <div class="admins-navbar">
        <md-tabs @md-changed="(tabId) => (activeTab = tabId)">
          <md-tab id="TabStatus" md-label="Изменить статус" />
          <md-tab id="TabComment" md-label="Оставить комментарий" />
          <!-- <md-tab id="TabScreenshot" md-label="Прикрепить скриншот" /> -->
        </md-tabs>
      </div>
    </div>
    <md-content class="md-scrollbar">
      <div class="md-size-100">
        <label>ID: {{ payment.id }}</label>
      </div>
      <div class="md-size-100">
        <label>Дата: {{ payment.prepareDate }}</label>
      </div>
      <div class="md-size-100">
        <label>Ник: {{ payment.user.userName }}</label>
      </div>
      <div class="md-size-100">
        <label>User ID: {{ payment.externalGameUserId }}</label>
      </div>
      <div class="md-size-100">
        <label>Wallet ID: {{ payment.externalCryptoWalletId }}</label>
      </div>
      <div class="md-size-100 mb-20">
        <label>
          Валюта:
          {{
            `${payment.cryptoCurrencyAmount}
             ${currencyTypes[payment.cryptoCurrencyType]}`
          }}
        </label>
      </div>

      <transition name="fade" mode="out-in">
        <ValidationProvider
          v-if="activeTab == 'TabStatus'"
          v-slot="{ errors }"
          class="md-size-100"
          mode="passive"
          tag="div"
        >
          <md-field :class="{ error: errors.length }">
            <ValidationError :errors="errors" />
            <label>Статус</label>
            <md-select v-model="payment.status">
              <md-option
                v-for="(status, index) in statuses"
                :key="`option_role_${status}`"
                :value="index"
              >
                {{ status }}
              </md-option>
            </md-select>
          </md-field>
        </ValidationProvider>
      </transition>
      <transition name="fade" mode="out-in">
        <ValidationProvider
          v-if="activeTab == 'TabComment'"
          v-slot="{ errors }"
          class="md-size-100"
          mode="passive"
          tag="div"
        >
          <md-field :class="{ error: errors.length }">
            <ValidationError :errors="errors" />
            <label>Комментарий</label>
            <md-input v-model="payment.adminComment" />
          </md-field>
        </ValidationProvider>
      </transition>
      <!-- <transition name="fade" mode="out-in">
        <ValidationProvider
          v-if="activeTab == 'TabScreenshot'"
          v-slot="{ errors }"
          class="md-size-100"
          mode="passive"
          tag="div"
        >
          <md-field :class="{ error: errors.length }">
            <ValidationError :errors="errors" />
            <label>Скриншот, подтверждающий оплату</label>
            <md-file @input="handleImage" />
          </md-field>
        </ValidationProvider>
      </transition> -->
    </md-content>
    <div class="buttons-wrap justify-end">
      <md-button class="md-raised md-success" @click="handleSave"
        >Сохранить</md-button
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { cloneDeep } from "lodash";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      payment: null,
      activeTab: "",
      file: null,
    };
  },
  computed: {
    ...mapState({
      statuses: (state) => state.statuses.statuses,
      currencyTypes: (state) => state.payments.currencyTypes,
    }),
  },
  created() {
    this.payment = cloneDeep(this.data);
  },
  methods: {
    ...mapActions(["setStatus", "setComment", "setScreen"]),
    ...mapMutations(["closeModal"]),
    handleImage(e) {
      if (e.target?.files?.length > 0) {
        const imageFile = URL.createObjectURL(e.target.files[0]);
        this.createImage(imageFile, this.convertImage);
      }
    },
    convertImage(image) {
      const canvas = this.drawImageToCanvas(image);
      const ctx = canvas.getContext("2d");

      let result = [];
      for (let y = 0; y < canvas.height; y++) {
        result.push([]);
        for (let x = 0; x < canvas.width; x++) {
          let data = ctx.getImageData(x, y, 1, 1).data;
          result[y].push(data[0]);
          result[y].push(data[1]);
          result[y].push(data[2]);
        }
      }
      this.file = result;
    },
    drawImageToCanvas(image) {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      canvas.getContext("2d").drawImage(image, 0, 0, image.width, image.height);
      return canvas;
    },
    convertArray(array) {
      return JSON.stringify(array).replace(/\[/g, "{").replace(/\]/g, "}");
    },
    createImage(imageFile, callback) {
      const image = document.createElement("img");
      image.onload = () => callback(image);
      image.setAttribute("src", imageFile);
    },
    async handleSave() {
      let response = null;
      if (this.activeTab == "TabStatus") {
        response = await this.setStatus({
          id: this.payment.id,
          status: this.payment.status,
        });
      }
      if (this.activeTab == "TabComment") {
        response = await this.setComment({
          id: this.payment.id,
          adminComment: this.payment.adminComment,
        });
      }
      if (this.activeTab == "TabScreenshot") {
        response = await this.setScreen({
          id: this.payment.id,
          screenShot: this.file,
        });
      }
      if (response) {
        this.$notify({
          message: response.message,
          icon: response.success ? "task_alt" : "do_not_disturb_alt",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: response.success ? "primary" : "secondary",
        });
        if (response.success) {
          this.closeModal();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: relative;
  height: auto;
  padding: 20px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 5px;
  background: #fff;
  width: 850px;
  &-content {
    padding: 20px;
    height: 350px;
    overflow-y: scroll;
  }
}
.admins-wrap {
  padding-top: 40px;
}

.admins-navbar {
  margin-bottom: 20px;

  &::v-deep {
    .md-tabs-navigation {
      background-color: #58b05c !important;
      padding: 20px;
    }
  }
}
.mb-20 {
  margin-bottom: 20px;
}
</style>
